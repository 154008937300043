<template>
  <v-menu offset-y style="box-shadow: none" bottom>
    <template v-slot:activator="{ on }" class="pa-0 ma-0">
      <v-list style="background-color: transparent" class="ma-0 pa-0 pl-7">
        <v-list-item class="pa-0 ma-0" :class="authorized ? '' : 'color-white'">
          <v-list-item-action v-on="on" class="ma-0 pa-0">
            <div class="d-flex align-center" style="cursor: pointer">
              <div class="font-family-raleway-medium">
                <div class="font-family-raleway-medium d-flex align-center">
                  <img
                    :src="selectedLanguage.value === 'en' ? engImg : ruImg"
                    alt=""
                    width="20"
                    height="15"
                  />
                  <!--                  <img :src="ruImg" alt="" width="40" height="40"/>-->
                  <span class="ml-3">{{ selectedLanguage.label }}</span>
                </div>
              </div>
              <v-icon class="ml-3" :color="!authorized ? '#fff' : ''"
                >mdi-chevron-down</v-icon
              >
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>
    <v-list style="width: 150px">
      <v-list-item
        @click="clickLanguage('en')"
        :class="selectedLanguage.value === 'en' ? 'active-class' : ''"
      >
        <v-list-item-title style="cursor: pointer">
          <div class="align-center d-flex">
            <img :src="engImg" alt="" width="20" height="15" />
            <span class="ml-3">Eng</span>
          </div>
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        @click="clickLanguage('ru')"
        :class="selectedLanguage.value === 'ru' ? 'active-class' : ''"
      >
        <v-list-item-title style="cursor: pointer">
          <div class="align-center d-flex">
            <img :src="ruImg" alt="" width="20" height="15" />
            <span class="ml-3">Ru</span>
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import engImg from "../assets/img/eng.jpeg";
import ruImg from "../assets/img/ru.jpeg";

export default {
  name: "SelectLanguageComponent",
  props: {
    authorized: Boolean,
  },
  data() {
    return {
      language: "en",
      ruImg,
      engImg,
      selectedLanguage: {},
      languages: [
        {
          label: "Eng",
          value: "en",
        },
        {
          label: "Ru",
          value: "ru",
        },
      ],
    };
  },
  created() {
    let lang = "";
    const currentLanguage = this.$i18n.locale;
    const langLocalstorage = localStorage.getItem("lang");
    if (langLocalstorage) {
      lang = langLocalstorage;
      this.$i18n.locale = langLocalstorage;
    } else {
      lang = currentLanguage;
    }
    this.selectedLanguage = this.languages.find((item) => item.value === lang);
  },
  methods: {
    clickLanguage(val) {
      this.$i18n.locale = val;
      this.selectedLanguage = this.languages.find((item) => item.value === val);
      localStorage.setItem("lang", val);
    },
  },
};
</script>

<style scoped lang="scss">
.language-select {
  &:hover {
    background-color: #f6f9fc !important;
  }
}
.active-class {
  background-color: #f6f9fc !important;
}
</style>
